<template>
  <div class="container">
    <div class="loginCon pucontainer">
      <h5>{{$t('login_mod.reg_t')}}</h5>
      <q-form @submit="onSubmit">
        <div class="inputdiv" v-if="change">
          <q-input
            autofocus
            maxlength="11"
            type="tel"
            v-model="form.phone"
            :label="$t('login_mod.phone_t')"
            :rules="[
              (val) => (val !== null && val !== '') || $t('login_mod.enter_phone'),
              (val) => phoneRule(val) || $t('login_mod.phone_format')
            ]"
          />
          <p>
            {{$t('login_mod.ro_t')}}
            <span
              class="changet"
              @click="change = false"
            >{{$t('login_mod.email_t')}}</span>
          </p>
        </div>
        <div class="inputdiv" v-else>
          <q-input
            autofocus
            maxlength="30"
            type="email"
            v-model="form.email"
            :label="$t('login_mod.email_t')"
            :rules="[
              (val) => (val !== null && val !== '') || $t('login_mod.enter_email'),
              (val) => emailRule(val) || $t('login_mod.email_format')
            ]"
          />
          <p>
            {{$t('login_mod.ro_t')}}
            <span
              class="changet"
              @click="change = true"
            >{{$t('login_mod.phone_t')}}</span>
          </p>
        </div>
        <div class="inputdiv">
          <div class="flexinput">
            <q-input
              type="text"
              maxlength="6"
              v-model="form.code"
              :label="$t('login_mod.verification_code')"
              :rules="[(val) => (val !== null && val !== '') || $t('login_mod.enter_verification_code')]"
            >
              <template v-slot:append>
                <q-btn
                  :loading="SendCodeLoading"
                  :disable="SendCodeDisabled"
                  color="primary"
                  :label="$t('login_mod.send_verification_code') + time"
                  @click="sendCode()"
                />
              </template>
            </q-input>
          </div>
        </div>
        <div class="inputdiv">
          <q-input
            type="password"
            maxlength="30"
            v-model="form.pwd"
            :label="$t('login_mod.pwd_t')"
            :rules="[
              (val) => (val !== null && val !== '') || $t('login_mod.enter_pwd'),
              (val) => pwdRule(val) || $t('login_mod.pwd_format')
            ]"
          />
        </div>
        <div class="inputdiv">
          <q-input
            maxlength="30"
            type="password"
            v-model="form.pwdAgain"
            :label="$t('login_mod.re_pwd')"
            :rules="[
              (val) => (val !== null && val !== '') || $t('login_mod.enter_pwd'),
              (val) => form.pwd === form.pwdAgain || $t('login_mod.no_one_pwd')
            ]"
          />
        </div>
        <div class="inputdiv">
          <q-input
            maxlength="20"
            v-model="form.name"
            :label="$t('login_mod.name')"
            :rules="[(val) => (val !== null && val !== '') || $t('login_mod.name_input')]"
          />
        </div>
        <div class="inputdiv">
          <q-input maxlength="10" v-model="form.Invitationcode" :label="$t('login_mod.invitecode')" />
        </div>
        <div class="btndiv">
          <router-link to="/login" class="gologin">{{$t('login_mod.go_login')}}</router-link>
          <q-btn type="submit" class="full-width" color="primary" :label="$t('login_mod.reg_t')" />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import { upDatedHead } from '@/common/upDatedHead'
import { phoneReg, emailReg } from '@/api/login'
import { phoneRule, emailRule, pwdRule } from '@/common/formRules'
export default {
  data () {
    return {
      change: true, // true 为手机登录，false为邮箱登录
      form: {
        phone: '',
        email: '',
        pwd: '',
        code: '',
        pwdAgain: '',
        name: '',
        Invitationcode: ''
      },
      SendCodeLoading: false,
      SendCodeDisabled: false,
      time: ''
    }
  },
  methods: {
    async onSubmit () {
      if (this.change) {
        const res = await phoneReg({
          mark: this.form.phone,
          pwd: this.form.pwd,
          code: this.form.code,
          name: this.form.name,
          Invitationcode: this.form.Invitationcode
        })
        if (res.status === 3) {
          upDatedHead()
          this.$router.push('/')
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      } else {
        const res = await emailReg({
          mark: this.form.email,
          pwd: this.form.pwd,
          code: this.form.code,
          name: this.form.name,
          Invitationcode: this.form.Invitationcode
        })
        if (res.status === 3) {
          upDatedHead()
          this.$router.push('/')
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      }
    },
    // 发送验证码
    async sendCode () {
      if (this.SendCodeLoading || this.SendCodeDisabled) {
        return
      }
      this.SendCodeLoading = true
      if (this.change) {
        if (this.form.phone === '' || !phoneRule(this.form.phone)) {
          this.$q.notify(this.$t('login_mod.phone_format'))
          this.SendCodeLoading = false
          return
        }
        const res = await phoneReg({
          mark: this.form.phone
        })
        if (res.status === 1) {
          this.timerStart()
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      } else {
        if (this.form.email === '' || !emailRule(this.form.email)) {
          this.$q.notify(this.$t('login_mod.email_format'))
          this.SendCodeLoading = false
          return
        }
        const res = await emailReg({
          mark: this.form.email
        })
        if (res.status === 1) {
          this.timerStart()
        } else {
          this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
        }
      }
      this.SendCodeLoading = false
    },
    // 倒计时开始
    timerStart () {
      if (this.SendCodeDisabled) {
        return
      }
      this.SendCodeDisabled = true
      let timer = null
      this.time = 60
      timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearTimeout(timer)
          this.time = ''
          this.SendCodeDisabled = false
        }
      }, 1000)
    }
  },
  setup () {
    return {
      pwdRule,
      emailRule,
      phoneRule
    }
  }
}
</script>

<style lang="scss" scoped>
.loginCon {
  margin: 87px auto;
  h5 {
    text-align: center;
  }
  .inputdiv {
    width: 530px;
    margin: 10px auto;
    margin-top: 50px;
    p {
      margin-top: 5px;
      display: flex;
      align-items: center;
      .changet {
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .btndiv {
    width: 530px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: right;
    .gologin {
      display: block;
      color: #666;
      margin-bottom: 5px;
    }
  }
}
</style>
